import { styled } from '@naan/stitches.config';

export const VideoMetaDescription = styled('div', {
  textStyle: 'body-bold',
  background: '$globalBlack-alpha-60',
  backdropFilter: 'blur(6px)',
  borderRadius: '$radii$medium',
  color: '$globalWhite',
  padding: '8px 12px',
  zIndex: 2,
});
