import * as React from 'react';
import { lazyComponent as $ } from 'lib/lazy-component';
import { Navigate, Route, Routes } from 'react-router-dom';

import { SignInLayout } from './auth/sign-in-layout';
import { AppWideRouteLayout } from './layouts/app-wide-route-layout';
import { AppRouteLayout } from './layouts/app-route-layout';
import { AuthRouteLayout } from './layouts/auth-route-layout';
import { ProfileLayout } from 'components/layouts/profile-layout';
import { TeachLayout } from 'components/layouts/teach-layout';
import { LearnLayout } from 'components/layouts/learn-layout';
import { AdminLayout } from 'components/layouts/admin-layout';

// we need this to load immediately, so no lazy loading
import { RedirectToStripeScreen } from './redirect-to-stripe-screen';
import { RedeemCodeScreen } from './redeem-code-screen';
import { NotFoundScreen } from './not-found-screen';

// 'noop' version substituted for staging, beta, live deployments
import { ShowcaseScreen } from './showcase/showcase-screen-__INTERNAL__';

import { DevToolsScreen } from './dev-tools';

// disabling dynamic importing until we figure out what's going on

import {
  defaultPath,
  homePath,
  signInPath,
  teachHomePath,
} from 'components/nav/path-helpers';

// @elliottjf, @frank, are wqe still using the CalendarScreen?
import { CalendarScreen } from './learn/soundbites/calendar-screen';

const DashboardScreen = $(() => import('./learn/dashboard-screen'));
const StoryListScreen = $(() => import('./learn/story-list-screen'));
const ProgressScreen = $(() => import('./progress-screen'));
const CollectionListScreen = $(() => import('./learn/collection-list-screen'));
const CollectionDetailScreen = $(() => import('./learn/collection-screen'));

const SignInPasswordScreen = $(() => import('./auth/sign-in-password-screen'));
const EmailCodeScreen = $(() => import('./auth/email-code-screen'));
const SignInCodeScreen = $(() => import('./auth/sign-in-code-screen'));
const SignUpNameScreen = $(() => import('./auth/sign-up/sign-up-name-screen'));
const SignUpEmailScreen = $(
  () => import('./auth/sign-up/sign-up-email-screen')
);
const SignInGoogleScreen = $(() => import('./auth/sign-in-google-screen'));
const LogoutHandler = $(() => import('./logout-handler'));
const AffiliateAttribution = $(() => import('./affiliate-attribution'));
const EnableVocabExport = $(() => import('./profile/enable-vocab-export'));

const StoryDetailOrInterstitialScreen = $(
  () =>
    import('./learn/story-detail-screen/story-detail-or-interstitial-screen')
);
const StudyScreen = $(() => import('./learn/study-screen'));
const SlugSoundbiteScreen = $(
  () => import('./learn/soundbites/slug-soundbite-screen')
);
const AccountScreen = $(() => import('./account-screen'));
const SubscribeScreen = $(() => import('./profile/subscribe-screen'));

const ClassroomsRedirection = $(() => import('./classrooms-redirection'));
const ClassroomsScreen = $(() => import('./classrooms-screen'));
const DiscoverScreen = $(() => import('./discover-screen'));
const FavoritesScreen = $(() => import('./favorites-screen'));
const ClassroomScreen = $(() => import('./classroom-screen'));
const AssignmentScreen = $(() => import('./classroom-assignment-screen'));
const VolumeDetailScreen = $(() => import('./volume-detail-screen'));
const SchoolActivationScreen = $(() => import('./school-activation-screen'));

const ExitSurveyScreen = $(() => import('./forms/exit-survey-screen'));
const AccountClosedScreen = $(() => import('./account-closed-screen'));
const CreateAccountScreen = $(() => import('./auth/create-account-screen'));
const CloseAccountScreen = $(() => import('./close-account-screen'));
const SupportScreen = $(() => import('./support-screen'));
const TimedOfferScreen = $(() => import('./timed-offer-screen'));

// @elliottjf, @frank, are we still using this Gift Coupons screen? I don't see it linked anywhere
const GiftCouponsScreen = $(() => import('./gift-coupons-screen'));

// admin screens
const AdminSoundbiteScreen = $(() => import('./admin/admin-soundbite-screen'));
const ExcerptListScreen = $(() => import('./admin/excerpt-list-screen'));
const CaliScriptScreen = $(() => import('./teach/cali-script-screen'));
const MasalaPreviewScreen = $(() => import('./admin/masala-preview-screen'));
const SelectCatalog = $(() => import('./admin/select-catalog'));
const SyncCatalogUrls = $(() => import('./admin/sync-catalog-urls'));
const SyncStripeData = $(() => import('./admin/sync-stripe-data'));

const redeemRoutePaths = [
  '/redeem/:code',
  '/redeem-nav-gift/:code',
  '/students/join/:code',
];

const FallbackRoute = () => {
  return <NotFoundScreen />;
};

export const SpaRoutes = () => {
  return (
    <Routes>
      <Route element={<AppWideRouteLayout />}>
        {/* this is the route we render when the user visits the root path directly `/` */}
        <Route index element={<Navigate to={defaultPath()} replace />} />

        {/* These are a bunch of `redeem` something routes, all rendering the same thing */}
        {redeemRoutePaths.map(path => (
          <Route path={path} element={<RedeemCodeScreen />} key={path} />
        ))}

        {/* store affiliate code to LocalState, then redirect to branded dashboard */}
        <Route path="/aff/:code" element={<AffiliateAttribution />} />

        {/* auth routes */}
        {/* <Route path="/welcome" element={<WelcomeScreen />} /> */}
        <Route
          path="/welcome"
          element={<Navigate to={defaultPath()} replace />}
        />
        <Route path="/auth" element={<AuthRouteLayout />}>
          <Route index element={<Navigate to={signInPath()} replace />} />
          <Route element={<SignInLayout />}>
            <Route path="sign-in" element={<SignInPasswordScreen />} />
            <Route path="sign-in-google" element={<SignInGoogleScreen />} />
            <Route path="email-code" element={<EmailCodeScreen />} />
            <Route path="sign-in-code" element={<SignInCodeScreen />} />
          </Route>
          <Route path="create-account" element={<CreateAccountScreen />} />
        </Route>
        <Route path="/auth/create-account">
          <Route path="name" element={<SignUpNameScreen />} />
          <Route path="email" element={<SignUpEmailScreen />} />
        </Route>

        {/* handle marketing site link, which redirects differently based on auth/user state */}
        <Route path="/classrooms" element={<ClassroomsRedirection />} />

        <Route path="/app" element={<Navigate to={homePath()} replace />} />

        <Route path="app/profile/subscribe" element={<SubscribeScreen />} />

        <Route
          path="/app/profile"
          element={<AppRouteLayout component={ProfileLayout} />}
        >
          <Route index element={<Navigate to="account" replace />} />
          <Route path="account" element={<AccountScreen />} />
          {/* <Route path="subscribe" element={<SubscribeScreen />} /> */}
          <Route path="gift-coupons" element={<GiftCouponsScreen />} />
          <Route path="schools" element={<SchoolActivationScreen />} />
          <Route path="close-account" element={<CloseAccountScreen />} />
          <Route path="dev-tools/*" element={<DevToolsScreen />} />
          <Route path="enable-vocab-export" element={<EnableVocabExport />} />
        </Route>

        <Route
          path="/app/page"
          element={<AppRouteLayout component={ProfileLayout} />}
        >
          <Route path="exit-survey" element={<ExitSurveyScreen />} />
        </Route>

        <Route path="/app/offer" element={<TimedOfferScreen />} />

        <Route
          path="/app/support"
          // @armando, the LearnLayout expects an :l2 path element
          // i'll hack around it for now, but this deserves a bit more thought
          element={<AppRouteLayout component={LearnLayout} />}
        >
          <Route index element={<SupportScreen />} />
        </Route>

        <Route
          path="/app/teach"
          element={<AppRouteLayout component={TeachLayout} />}
        >
          {/* for now will redirect to either ./discover or ./classrooms */}
          <Route index element={<Navigate to={teachHomePath()} replace />} />
          <Route path="classrooms">
            <Route index element={<ClassroomsScreen />} />
            <Route path=":id">
              <Route index element={<ClassroomScreen />} />
              <Route path="assignments/:slug" element={<AssignmentScreen />} />
            </Route>
          </Route>
          <Route path="discover" element={<DiscoverScreen />} />
          <Route path="discover/v/:slug" element={<VolumeDetailScreen />} />
          <Route path="favorites" element={<FavoritesScreen />} />
          <Route path="favorites/v/:slug" element={<VolumeDetailScreen />} />
        </Route>

        <Route
          path="/app/learn"
          element={<AppRouteLayout component={LearnLayout} />}
        >
          <Route index element={<Navigate to="home" replace />} />
          <Route path="home" element={<DashboardScreen />} />
          <Route path="stories" element={<StoryListScreen />} />
          {/* <Route
            path="stories/:slug/vocab-review"
            element={<VocabReviewScreen />}
          /> */}
          <Route
            path="stories/:slug/study/:unitNumber/:chapterPosition"
            element={<StudyScreen />}
          />

          <Route path="soundbites" element={<CalendarScreen />} />
          <Route
            path="soundbites/:soundbiteSlug"
            element={<SlugSoundbiteScreen />}
          />

          <Route
            path="stories/:storySlug/soundbites/:soundbiteSlug"
            element={<SlugSoundbiteScreen />}
          />

          <Route
            path="stories/:slug/*"
            element={<StoryDetailOrInterstitialScreen />}
          />

          <Route path="progress" element={<ProgressScreen />} />
          <Route path="collections" element={<CollectionListScreen />} />
          <Route
            path="collections/:slug"
            element={<CollectionDetailScreen />}
          />
        </Route>

        <Route path="/scripts">
          <Route path={':flavor/:slug'} element={<CaliScriptScreen />} />
          {/* <Route path={'scaffolded/:slug'} element={<StoryGuideScreen />} /> */}
        </Route>

        <Route path="/logout" element={<LogoutHandler />} />
        {/* <Route path="/switch" element={<L2SwitchGuard />} /> */}
        {/* <Route path="/skip-auth" element={<SkipAuthHandler />} /> */}

        <Route path="/account-closed" element={<AccountClosedScreen />} />

        {/* <Route
              path="/appsupport/back-to-app"
              element={<BackToAppScreen />}
            /> */}

        <Route>
          <Route
            path="/appsupport/subscribe"
            element={<RedirectToStripeScreen />}
          />
          <Route
            path="/appsupport/subscribe/:token"
            element={<RedirectToStripeScreen />}
          />
        </Route>
        <Route path="/showcase/*" element={<ShowcaseScreen />} />

        <Route path="/admin" element={<AdminLayout />}>
          <Route index element={<Navigate to="excerpts" replace />} />
          {/* <Route index element={<AdminHomeScreen />} /> */}
          <Route path="excerpts" element={<ExcerptListScreen />} />
          <Route path="preview" element={<AdminSoundbiteScreen />} />
          <Route path="sync-catalog-urls" element={<SyncCatalogUrls />} />
          <Route path="sync-stripe-data" element={<SyncStripeData />} />
        </Route>

        <Route path="/soundbite-demo" element={<Navigate to="/" replace />} />
        <Route path="/try-soundbite" element={<Navigate to="/" replace />} />
        <Route path="/daily-soundbite" element={<Navigate to="/" replace />} />
        <Route
          path="/soundbite-orientation"
          element={<Navigate to="/" replace />}
        />

        {/* // used to preview editorial content independent of selected catalog */}
        <Route path="/masala-preview" element={<MasalaPreviewScreen />} />
        <Route path="/admin/select-catalog/:slug" element={<SelectCatalog />} />

        <Route path="*" element={<FallbackRoute />}></Route>
      </Route>
    </Routes>
  );
};
