import * as React from 'react';
import { FullScreenLoader } from 'components/ds/modals';

// Helper type for lazy loading
type LazyModule<T = any> = Promise<{ [key: string]: React.ComponentType<T> }>;

// Lazy loading wrapper that handles both default and named exports
export const lazyComponent = <T extends {}>(importFn: () => LazyModule<T>) => {
  const LazyComponent = React.lazy(() =>
    importFn().then(module => ({
      default: module.default || Object.values(module)[0],
    }))
  );

  return (props: T) => (
    <React.Suspense fallback={<FullScreenLoader />}>
      <LazyComponent {...props} />
    </React.Suspense>
  );
};
