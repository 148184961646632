import { useRef, useState } from 'react';

/**
 * useCopyPowers hook
 * Abstracts the functionality to make components that put things in the DOM
 *
 */

export const useCopyPowers = (options: { activeDuration?: number } = {}) => {
  const ref = useRef<HTMLInputElement | HTMLTextAreaElement>(null);
  const [active, setActive] = useState(false);
  const { activeDuration = 1000 } = options;

  const copyAction = async () => {
    try {
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(ref.current?.value || '');
      } else {
        ref.current?.select();
        // @ts-ignore
        document.execCommand('copy');
      }

      setActive(true);
      window.setTimeout(() => {
        setActive(false);
      }, activeDuration);
    } catch (err) {
      // console.error('Failed to copy to clipboard:', err);
    }
  };

  return { ref, active, copyAction };
};

export const copyToClipboard = async (text: string) => {
  try {
    if (navigator.clipboard) {
      await navigator.clipboard.writeText(text);
    } else {
      const textArea = document.createElement('textarea');
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);
    }
  } catch (err) {
    // console.error('Failed to copy to clipboard:', err);
  }
};

// export const useCopyPowers = (options: { activeDuration?: number } = {}) => {
//   const ref = useRef<HTMLInputElement | HTMLTextAreaElement>();
//   const [active, setActive] = useState(false);
//   const { activeDuration = 1000 } = options;

//   const copyAction = () => {
//     ref.current?.select();
//     // @ts-ignore
//     document.execCommand('copy');
//     setActive(true);
//     window.setTimeout(() => {
//       setActive(false);
//     }, activeDuration);
//   };

//   return { ref, active, copyAction };
// };
