import {
  getParentOfType,
  ModelTreeNode,
  snap,
  TSTStringMap,
} from 'ts-state-tree/tst-core';
import { UserManager } from './user-manager';
import { bugsnagNotify } from '@app/notification-service';
// import { track } from 'app/track';

import { createLogger } from 'app/logger';
import { track } from '@app/track';
import { TipKey, tipKeys } from '@app/onboarding/onboarding-service';
import { UserData } from './user-data';
import { Root } from '../root';
import { getBaseRoot } from '../app-root';
import { AppFactory } from '@app/app-factory';
import { AchievementsService } from '@app/onboarding/achievements';
import { observable } from 'mobx';
import { events } from '@common/instrumentation/events';
import { LocaleCode } from '@utils/util-types';
import { clearMapWithValue } from '@utils/util';
const log = createLogger('um:user-settings');

/**
 * UserSettings
 *
 * non-study view user configuration and misc data
 */
export class UserSettings extends ModelTreeNode {
  static CLASS_NAME = 'UserSettings' as const;

  static create(snapshot: any) {
    return super.create(UserSettings, snapshot) as UserSettings;
  }

  dismissedMessageSet: TSTStringMap<boolean> = snap({});
  // legacy data, automatically migrated upon load
  // dismissedMessages: string[];

  // onboarding tips
  dismissedTipSet: TSTStringMap<boolean> = snap({});
  // welcomeTipsDisabled: boolean = false;
  // welcomeTipsEnabled: boolean;

  // when true, set locale to L2
  immersiveViewEnabled: boolean = false;

  // when true, we hide the labels in the sentence toolbar
  compactSentenceToolbarEnabled: boolean = false;

  showVocabListExportOption: boolean = false;
  autoDownloadDisabled: boolean = false;

  // tracks the story slug of the most recently seen new story floating button
  lastSeenNewStoryButtonSlugMap: TSTStringMap<string> = snap({}); // scoped per L2
  lastViewedNewStoryWidgetSlug: string; // obsolete

  @observable
  uiTheme: 'light' | 'dark' | 'system' = 'system';

  get root(): Root {
    return getBaseRoot(this);
  }

  get userManager(): UserManager {
    return getParentOfType(this, UserManager) as UserManager;
  }

  get userData(): UserData {
    return getParentOfType(this, UserData) as UserData;
  }

  dismissMessage(key: string) {
    log.debug(`dismissMessage: ${key}`);
    this.dismissedMessageSet.set(key, true);
    this.userManager.persistUserData().catch(bugsnagNotify);
  }

  messageIsDismissed(key: string) {
    // return this.dismissedMessageSet.has(key);
    const val = this.dismissedMessageSet.get(key);
    if (val === undefined) {
      return false;
    }
    return val === true;
  }

  resetMessage(key: string) {
    this.dismissedMessageSet.set(key, false);
  }

  dismissTip(key: TipKey) {
    log.debug(`dismissTip: ${key}`);
    this.dismissedTipSet.set(key, true);
    this.userManager.persistUserData().catch(bugsnagNotify);
    track('onboarding__tip_dismissed', { key });
  }

  tipIsDismissed(key: TipKey) {
    // if (!this.welcomeTipsEnabled) {
    //   return true;
    // }
    const val = this.dismissedTipSet.get(key);
    if (val === undefined) {
      return false;
    }
    return val === true;
  }

  resetTip(key: TipKey) {
    // this.dismissedMessageSet.delete(key);
    this.dismissedTipSet.set(key, false);
  }

  // dismissAllTips() {
  //   log.debug(`dismissAllTips`);
  //   for (const key of tipKeys) {
  //     this.dismissedTipSet.set(key, true);
  //   }
  //   this.userManager.persistUserData().catch(bugsnagNotify);
  // }

  // handleTipAction(tipKey: TipKey, action: OnboardingDialogAction) {
  //   switch (action) {
  //     case 'skipTips':
  //       this.welcomeTipsEnabled = false; // persisted with the dismissTip call
  //       this.dismissTip(tipKey);
  //       break;
  //     case 'gotIt':
  //       this.dismissTip(tipKey);
  //       break;
  //     default:
  //       log.info('unhandled action', action);
  //   }
  // }

  // resets all onboarding state (Quickstart guide)
  resetAllTips() {
    log.debug(`resetAllTips`);
    for (const key of tipKeys) {
      this.dismissedTipSet.set(key, false);
    }
    // this.welcomeTipsEnabled = true;

    AchievementsService.instance.resetAllAchievements();
    this.userData.resetOnboardingStory();
    this.userData.playerSettings.resetToDefault();

    this.userManager.persistUserData().catch(bugsnagNotify);
  }

  // toggleWelcomeTips(): void {
  //   this.welcomeTipsEnabled = !this.welcomeTipsEnabled;
  //   this.userManager.persistUserData().catch(bugsnagNotify);
  // }

  // migrateListToMap(): boolean {
  //   if (!this.dismissedMessages) return false;

  //   const count = this.dismissedMessages.length;
  //   log.info(`migrate ${count} dismissedMessages to map`);
  //   runInAction(() => {
  //     for (const key of this.dismissedMessages) {
  //       this.dismissedMessageSet.set(key, true);
  //     }
  //     this.dismissedMessages = undefined;
  //   });
  //   return true;
  // }

  updateLastSeenNewStoryButtonSlug(l2: LocaleCode, slug: string) {
    // this.lastViewedNewStoryWidgetSlug = slug;
    this.lastSeenNewStoryButtonSlugMap.set(l2, slug);
    this.userManager.persistUserData().catch(bugsnagNotify);
  }

  hasNewStoryButtonBeenSeen(l2: LocaleCode, slug: string) {
    return (
      this.lastSeenNewStoryButtonSlugMap.get(l2) === slug ||
      this.lastViewedNewStoryWidgetSlug === slug // legacy data check, can be removed in future release
    );
  }

  lastSeenNewStoryButtonSlug(l2: LocaleCode) {
    return this.lastSeenNewStoryButtonSlugMap.get(l2);
  }

  resetLastSeenNewStoryButtonSlug() {
    // localStorage.removeItem(NEW_STORY_KEY);
    // this.lastViewedNewStoryWidgetSlug = null;
    clearMapWithValue(this.lastSeenNewStoryButtonSlugMap, '');
    this.userManager.persistUserData().catch(bugsnagNotify);
  }

  toggleImmersiveView() {
    this.immersiveViewEnabled = !this.immersiveViewEnabled;
    this.root.applyLocale();
    this.userManager.persistUserData().catch(bugsnagNotify);
    track(events.settings.toggleImmersiveView, {
      enabled: this.immersiveViewEnabled,
    });
  }

  toggleCompactSentenceToolbar() {
    this.compactSentenceToolbarEnabled = !this.compactSentenceToolbarEnabled;
    this.userManager.persistUserData().catch(bugsnagNotify);
    track(events.settings.toggleCompactSentenceToolbar, {
      enabled: this.compactSentenceToolbarEnabled,
    });
  }

  async enableVocabExport(): Promise<void> {
    this.showVocabListExportOption = true;
    await this.userManager.persistUserData();
    track(events.settings.showVocabExport, { enabled: true });
  }

  toggleVocabExport() {
    this.showVocabListExportOption = !this.showVocabListExportOption;
    this.userManager.persistUserData().catch(bugsnagNotify);
    track('settings__show_vocab_export', {
      enabled: this.showVocabListExportOption,
    });
  }

  toggleAutoDownload() {
    this.autoDownloadDisabled = !this.autoDownloadDisabled;
    this.userManager.persistUserData().catch(bugsnagNotify);
    AppFactory.assetCacher
      .updateForceDisabled(this.autoDownloadDisabled)
      .catch(bugsnagNotify);
    track(events.settings.autoDownload, {
      enabled: !this.autoDownloadDisabled,
    });
  }

  disableAutoDownload() {
    if (this.autoDownloadEnabled) {
      this.toggleAutoDownload();
    }
  }

  get autoDownloadEnabled() {
    return !this.autoDownloadDisabled;
  }
}
