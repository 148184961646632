import * as React from 'react';
import type { JSX } from 'react';
import { ErrorBoundary } from 'components/error-boundary';
import { Route, Link, Routes, Navigate } from 'react-router-dom';
import { useSwitch } from '@naan/hooks/use-switch';
import { ArrowLeftSmallIcon } from 'naan/icons/arrow-left-icon';
import { ArrowRightSmallIcon } from 'naan/icons/arrow-right-icon';
import { appConfig } from '@app/env';
import { styled } from 'naan/stitches.config';
import { homePath } from 'components/nav/path-helpers';

type TShowcaseRoute = {
  Screen: () => JSX.Element;
  pathname: string;
  naanReady?: boolean;
  navlabel?: string;
};

let routes: TShowcaseRoute[] = [];

// dynamically import all showcase pages
if (appConfig.includeShowcase) {
  const modules = import.meta.glob('./pages/*.tsx', {
    eager: true,
  });

  routes = Object.entries(modules).map(([_, module]) => {
    // const { Screen, pathname, navlabel, naanReady } = module;
    return module as TShowcaseRoute;
  });
}

const Sidebar = styled('div', {
  position: 'fixed',
  top: 0,
  left: 0,
  display: 'flex',
  alignItems: 'flex-start',
  transform: 'translateX(-300px)',
  transitionProperty: 'transform',
  zIndex: 1,

  '& > nav': {
    height: '100vh',
    overflow: 'auto',
    width: 300,
    backgroundColor: '$black-alpha-90',
    display: 'flex',
    flexDirection: 'column',

    '& > a': {
      padding: '1rem',
      color: '$white-alpha-60',
      textStyle: 'body-bold',
      textDecoration: 'none',
      '&:hover': {
        color: '$white-alpha-100',
      },
    },
  },

  variants: {
    open: {
      true: {
        transform: 'translateX(0px)',
      },
    },
  },
});

export const ShowcaseScreen = () => {
  const [sidebar, { toggle }] = useSwitch(false);
  // let { path } = useRouteMatch();
  // const path = '/showcase';

  if (!appConfig.includeShowcase) {
    return <Navigate to={homePath()} />;
  }

  // const root = AppFactory.root; // experimentally ensure creation before volume screen visited

  return (
    <>
      <ErrorBoundary>
        <Routes>
          {routes.map(route => (
            <Route
              key={route.pathname}
              path={route.pathname.replace(/^\//, '')}
              element={
                <ErrorBoundary>
                  <route.Screen />
                </ErrorBoundary>
              }
            />
          ))}
        </Routes>
      </ErrorBoundary>
      <Sidebar open={sidebar}>
        <nav>
          {routes.map(route => (
            <Link
              key={`${route.pathname}`}
              to={`${route.pathname.replace(/^\//, '')}`}
              onClick={toggle}
            >
              {route.navlabel}
              {route.naanReady ? ' 🆕' : null}
            </Link>
          ))}
        </nav>
        <button className="toggle" onClick={toggle}>
          {sidebar ? <ArrowLeftSmallIcon /> : <ArrowRightSmallIcon />}
        </button>
      </Sidebar>
    </>
  );
};
