/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useLocation, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { FullScreenLoader } from 'components/ds/modals';
// import { ValidationError } from 'core/lib/errors';
import { AppFactory } from 'app/app-factory';
import {
  createAccountPath,
  profileHomePath,
  profilePath,
} from 'components/nav/path-helpers';
import {
  ApiMessageData,
  resolveApiMessage,
} from '@core/models/user-manager/api-messages';

const getRedirectAfterForPathname = (pathname: string) => {
  const pathMap: Record<string, string> = {
    'redeem-nav-gift': `${profilePath()}/gift-coupons`,
  };
  const pathRoot = pathname.substring(1, pathname.lastIndexOf('/') - 1);
  return pathMap[pathRoot] ?? profileHomePath();
};

export const RedeemCodeScreen = observer(() => {
  const { code } = useParams<{ code: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const { userManager } = AppFactory.root;

  useEffect(() => {
    const { pathname } = location;

    if (!userManager.authenticated) {
      navigate(createAccountPath({ after: pathname }));
    } else {
      const redirectAfter = getRedirectAfterForPathname(pathname);
      userManager.applyCoupon(code).then(
        (result?: ApiMessageData) => {
          const message = resolveApiMessage(result);
          if (message) {
            AppFactory.toastService.open({
              message,
              type: 'success',
            });
          }
          navigate(redirectAfter, { replace: true });
        },
        error => {
          // if (error instanceof ValidationError) {
          AppFactory.toastService.open({
            // i think in practice all errors will have a message.
            // to excercise the fall back here, i needed to `throw new Error()`
            message: error.message || 'An unexpected error has occured', // todo revsit generic error messaging
            type: 'error',
          });

          navigate(profileHomePath(), { replace: true });

          return;
          // }
          // throw error;

          // don't rethrow until we have safer handling of unhandled errors
        }
      );
    }
  }, []);

  return <FullScreenLoader />;
});
